import React, { useState, useEffect } from "react";
import {
    Container,
    Table,
    Card,
    CardBody,
    CardHeader,
    Spinner
  } from "reactstrap";
  
import { RAYDIUM_LATEST_TABLE_URL } from "../../constants/settings";
  
export default function RaydiumLatestTable({ dataUrl = `${RAYDIUM_LATEST_TABLE_URL}` }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(dataUrl);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();

        let rows = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(x => {
            try{
            return {
                contract_address: x.contract_address,
                name: x.name,
                short_name: x.short_name,
                price_usd: x.token_stats[x.token_stats.length - 1].price_usd,
                holders: x.token_stats[x.token_stats.length - 1].holders,
                holders_delta: x.token_stats.length > 1 ?x.token_stats[x.token_stats.length - 1].holders - x.token_stats[x.token_stats.length - 2].holders : 0,
                volume_buy_usd: x.token_stats[x.token_stats.length - 1].volume_buy_usd,
                volume_sell_usd: x.token_stats[x.token_stats.length - 1].volume_sell_usd,
                bundled_buy_usd: x.token_stats[x.token_stats.length - 1].bundled_buy_usd,
                bundled_sold_usd: x.token_stats[x.token_stats.length - 1].bundled_sold_usd,
                snipers_buy_usd: x.token_stats[x.token_stats.length - 1].snipers_buy_usd,
                snipers_sold_usd: x.token_stats[x.token_stats.length - 1].snipers_sold_usd,
                dev_sold_usd: x.token_stats[x.token_stats.length - 1].dev_sold_usd,
                dev_current_usd: x.token_stats[x.token_stats.length - 1].dev_current_usd,
                utc_timestamp: x.token_stats[x.token_stats.length - 1].utc_timestamp,
                bundled_buy_percentage: Number(x.token_stats[x.token_stats.length - 1].bundled_buy_percentage),
                bundled_sold_percentage: Number(x.token_stats[x.token_stats.length - 1].bundled_sold_percentage),
                snipers_buy_percentage: Number(x.token_stats[x.token_stats.length - 1].snipers_buy_percentage),
                snipers_sold_percentage: Number(x.token_stats[x.token_stats.length - 1].snipers_sold_percentage),
                dev_sold_percentage: Number(x.token_stats[x.token_stats.length - 1].dev_sold_percentage),
                dev_current_percentage: Number(x.token_stats[x.token_stats.length - 1].dev_current_percentage),
            }}
            catch(err){
                return undefined;
            }
        }).filter(x => x !== undefined)
        
        setTableData(rows); // Set the latest stats as the table data
        setError(null);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    // Fetch data immediately when component mounts
    fetchData();
    
    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 5000);
    
    // Clean up interval when component unmounts
    return () => clearInterval(intervalId);
  }, [dataUrl]);

  // Use sample data if no data has been fetched yet
  const displayData = tableData.length > 0 ? tableData : [];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardHeader className="d-flex justify-content-between align-items-center">
              <h4 className="card-title mb-0">Raydium Latest Data</h4>
              {loading && <Spinner size="sm" color="primary" />}
            </CardHeader>
            <CardBody>
              {error ? (
                <div className="alert alert-danger">{error}</div>
              ) : (
                <div className="table-responsive">
                  <Table 
                    className="table-striped table-bordered mb-0"
                    hover={false}
                    style={{
                      '--bs-table-hover-bg': 'transparent !important',
                      '--bs-table-hover-color': 'inherit !important'
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Short Name</th>
                        <th>Address</th>
                        <th>Price (USD)</th>
                        <th>Market Cap (USD)</th>
                        <th>Holders</th>
                        <th>Holders Delta</th>
                        <th>Volume Buy (USD)</th>
                        <th>Volume Sell (USD)</th>
                        <th>Bundled Buy</th>
                        <th>Bundled Sold</th>
                        <th>Snipers Buy</th>
                        <th>Snipers Sold</th>
                        <th>Dev Current</th>
                        <th>Dev Sold</th>
                        <th>UTC Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayData.map((item) => (
                        <tr 
                          key={item.contract_address || item.id} 
                          style={{cursor: 'pointer'}}
                          onClick={()=>{
                            window.open(`https://photon-sol.tinyastro.io/en/lp/${item.contract_address}`, '_blank');
                          }}
                        >
                          <td>{item.name}</td>
                          <td>{item.short_name}</td>
                          <td>{item.contract_address}</td>
                          <td>${item.price_usd.toFixed(9)}</td>
                          <td>${Math.round(item.price_usd * 1_000_000_000).toLocaleString()}</td>
                          <td>{item.holders}</td>
                          <td>{item.holders_delta > 0 ? `+${item.holders_delta}` : item.holders_delta}</td>
                          <td>${Math.round(item.volume_buy_usd).toLocaleString()}</td>
                          <td>${Math.round(item.volume_sell_usd).toLocaleString()}</td>
                          <td>${Math.round(item.bundled_buy_usd).toLocaleString()}<br/>{Math.round(item.bundled_buy_percentage)}%</td>
                          <td>${Math.round(item.bundled_sold_usd).toLocaleString()}<br/>{Math.round(item.bundled_sold_percentage)}%</td>
                          <td>${Math.round(item.snipers_buy_usd).toLocaleString()}<br/>{Math.round(item.snipers_buy_percentage)}%</td>
                          <td>${Math.round(item.snipers_sold_usd).toLocaleString()}<br/>{Math.round(item.snipers_sold_percentage)}%</td>
                          <td>${Math.round(item.dev_current_usd).toLocaleString()}<br/>{Math.round(item.dev_current_percentage)}%</td>
                          <td>${Math.round(item.dev_sold_usd).toLocaleString()}<br/>{Math.round(item.dev_sold_percentage)}%</td>
                          <td>{new Date(item.utc_timestamp).toLocaleString()}</td>
                        </tr>

                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}
